.story-top-padding {
    padding-top: 18em;
    padding-bottom: 10em;
}

.gradient-bg-color {
    background: rgb(242, 239, 212);
    background: linear-gradient(0deg, rgba(242, 239, 212, 1) 0%, rgba(219, 245, 250, 1) 25%, rgba(247, 219, 207, 1) 50%, rgba(240, 230, 188, 1) 75%, rgba(236, 255, 219, 1) 100%);
}

.story-padding {
    padding-top: 3em;
    padding-bottom: 3em;
}

.img-padding {
    padding-top: 4em;
    padding-bottom: 4em;
}

.paragraph-padding {
    padding-bottom: 4em;
}

hr.hr-text {
    position: relative;
    border: none;
    height: 0.5px;
    background: #999;
    width: 80%;
}

#storyNav {
    min-height: 3.5rem;
    background-color: #fff;
}

#storyNav .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: #64a19d;
    border: 1px solid #64a19d;
}

#storyNav .navbar-toggler:focus {
    outline: none;
}

#storyNav .navbar-brand {
    color: #000000;
    font-weight: 700;
    padding: 0.9rem 0;
}

#storyNav .navbar-nav .nav-item:hover {
    color: fade(#fff, 80%);
    outline: none;
    background-color: transparent;
}

#storyNav .navbar-nav .nav-item:active,
#storyNav .navbar-nav .nav-item:focus {
    outline: none;
    background-color: transparent;
}

@media (min-width: 992px) {
    #storyNav {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;
    }

    #storyNav .navbar-brand {
        padding: 0.5rem 0;
        color: rgba(63, 63, 63, 0.5);
    }

    #storyNav .nav-link {
        transition: none;
        padding: 2rem 1.5rem;
        color: rgba(63, 63, 63, 0.5);
    }

    #storyNav .nav-link:hover {
        color: rgba(63, 63, 63, 0.75);
    }

    #storyNav .nav-link:active {
        color: #fff;
    }

    #storyNav.navbar-shrink {
        background-color: #fff;
    }

    #storyNav.navbar-shrink .navbar-brand {
        color: #000000;
    }

    #storyNav.navbar-shrink .nav-link {
        color: #000000;
        padding: 1.5rem 1.5rem 1.25rem;
        border-bottom: 0.25rem solid transparent;
    }

    #storyNav.navbar-shrink .nav-link:hover {
        color: #64a19d;
    }

    #storyNav.navbar-shrink .nav-link:active {
        color: #467370;
    }

    #storyNav.navbar-shrink .nav-link.active {
        color: #64a19d;
        outline: none;
        border-bottom: 0.25rem solid #64a19d;
    }
}

body {
    margin: 0;
    font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

#story h1,
#story h2,
#story h3,
#story h4 {
    font-family: orpheuspro;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}

@font-face {
    font-family: "orpheuspro";
    src: url("https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

#story p {
    font-size: 110%;
}

#story li {
    font-size: 110%;
}